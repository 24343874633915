// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Austin = (props: PageProps) => (
  <Layout>
    <SEO title="Things to do in Austin" />
    <Link to="/">
      <div className="pt-10 md:pt-20">
    <p className="text-gray-600">⬅ Go back</p>
    <h1 className="text-7xl mb-3 mt-3">things to do in Austin</h1></div></Link>
  

  </Layout>
)

export default Austin
